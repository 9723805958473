import { apiSlice } from "./api/apiSlice";

const leadSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addLead: builder.mutation({
            query: (lead) => ({
                url: 'lead/create',
                method: 'POST',
                body: lead
            }),
            invalidatesTags: ["Leads"]
        }),
        getAllLeads: builder.query({
            query: () => ({
                url: 'lead/emp'
            }),
            transformResponse: (response, meta, arg) => response.leads,
            providesTags: ['Leads']
        }),
        getFavouriteLeads: builder.query({
            query: () => ({
                url: '/employee/favourite'
            }),
            transformResponse: (response, meta, arg) => response.leads.favouriteLead,
            providesTags: ['Leads']
        }),
        getFavouriteLeadsLatest: builder.query({
            query: () => ({
                url: 'employee/favourite/latest_leads'
            }),
            transformResponse: (response, meta, arg) => response.leads,
            providesTags: ['Leads']
        }),
        getSharedLeads: builder.query({
            query: () => ({
                url: '/leadSharing'
            }),
            transformResponse: (response, meta, arg) => response.leadSharingData,
            providesTags: ['Leads']
        }),
        getCalender: builder.query({
            query: () => ({
                url: 'task/calender'
            }),
            transformResponse: (response) => response.calender
        }),
        getTodoListTask: builder.query({
            query: (option) => ({
                url: `/task/overduetask?task=${option}`
            }),
            transformResponse: (response) => response.tasks,
            providesTags: ['Task']
        }),
        getSingleEmployeeLeads: builder.query({
            query: (id) => ({
                url: `/lead/emp/${id}`
            }),
            transformResponse: (response) => response.empLeads
        }),
        searchLeads: builder.query({
            query: ({ clientID, from, projectID, employeeID, classification, source, numTasks, page, limit }) => ({
                url: `/lead/manage_lead/filter?clientID=${clientID}&from=${from}&projectID=${projectID}&employeeId=${employeeID}&classification=${classification}&source=${source}&numTasks=${numTasks}&limit=${limit}&page=${page}`,
            }),
            transformResponse: (response, meta, arg) => response,
            providesTags: ['Leads']
        }),
        searchLeadsFilter: builder.query({
            query: ({ start, end, clientID, projectID, employeeID, leadID, page, pageSize, phoneNumber, callStatus, isNew, limit }) => ({
                url: `/lead/filter/leads?startDate=${start}&endDate=${end}&clientID=${clientID}&projectID=${projectID}&employeeId=${employeeID}&leadID=${leadID}&page=${page}&pageSize=${pageSize}&phoneNumber=${phoneNumber}&callStatus=${callStatus}&limit=${limit}${isNew ? `&isNew=${isNew}` : ''}`,
            }),
            transformResponse: (response, meta, arg) => response,
            providesTags: ['Leads']
        }),
        shareLead: builder.mutation({
            query: (lead) => ({
                url: 'leadSharing/create',
                method: 'POST',
                body: lead
            }),
            invalidatesTags: ["Leads"]
        }),
        assignLeads: builder.mutation({
            query: (leadAssignmentData) => ({
                url: '/lead/assign_inactive_leads',
                method: 'PUT',
                body: leadAssignmentData
            }),
            invalidatesTags: ["Leads"]
        }),
        singleassignLead: builder.mutation({
            query: (lead) => ({
                url: '/lead/assign_single_lead',
                method: 'PUT',
                body: lead
            }),
            invalidatesTags: ["Leads"]
        }),
        Projectassign: builder.mutation({
            query: ({ leadId, projectId }) => ({
                url: `/lead/edit_project/${leadId}`,
                method: 'PUT',
                body: { project: projectId },
            }),
            invalidatesTags: ['Leads'],
        }),
        addFeedback: builder.mutation({
            query: ({ leadId, body }) => ({
                url: `/lead/${leadId}/feedback`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ["Leads"]
        })
    })
})

export const { useGetSharedLeadsQuery, useShareLeadMutation, useAssignLeadsMutation, useSingleassignLeadMutation, useAddLeadMutation, useGetAllLeadsQuery, useGetFavouriteLeadsQuery, useGetFavouriteLeadsLatestQuery, useGetCalenderQuery, useGetTodoListTaskQuery, useLazyGetSingleEmployeeLeadsQuery, useLazySearchLeadsQuery, useLazySearchLeadsFilterQuery, useProjectassignMutation, useAddFeedbackMutation } = leadSlice;