export const colors = {
    lightWhite: '#F9F9F9',
    white:'#FFFFFF',
    inputWhite:'#F5F5F5',
    inputBorderWhite:'#DEDFDF',
    inputFeild:'rgba(72, 72, 72, 0.5)',
    // brown:'#795F2F',
    brown: '#249393', // official colors
    // brown:'rgb(141, 27, 17)',
    black: '#000000',
    textLight: 'rgba(72, 72, 72, 0.5)',
    textMed:'rgba(113, 115, 118, 1)',
    veryHot:'#E80B0B',
    hot:'#E04241',
    moderate:'#FABC0B',
    cold:'#02BFCD',
    veryCold:'#2D7AF4',
    text:'#12151B',
    textBlue:'#0072FD',
    starYellow:'#FCC01C',
    purple:'#6F51CC'
}