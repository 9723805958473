import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../../Shared/colors";
import { useGetHierarchyCompletedTaskQuery } from "../../features/taskSlice";
import moment from 'moment-timezone';

const columns = [
//   {
//     field: "client",
//     headerName: "Client",
//     width: 150,
//     renderCell: (params) => {
//       return (
//         <Tooltip title={params.row.client}>
//           <div>{params.row.client}</div>
//         </Tooltip>
//       );
//     },
//   },
//   {
//     field: "cellNo",
//     headerName: "Cell No",
//     width: 150,
//     renderCell: (params) => {
//       return <div>+{params.row.cellNo}</div>;
//     },
//   },
  {
    field: "clientName",
    headerName: "Client Name",
    width: 150,
  },
  {
    field: "clientPhone",
    headerName: "Client Phone",
    width: 150,
  },
  {
    field: "currentTaskType",
    headerName: "Task Type",
    width: 150,
  },
  {
    field: "currentSubTypeTask",
    headerName: "Task Sub Type",
    width: 150,
  },
  {
    field: "completedDate",
    headerName: "Completed Date",
    width: 200,
    renderCell: (params) => {
      let date = moment(params.row.completedDate).tz('Asia/Karachi');
      let formattedDate = date.format('ddd, MMM D, YYYY h:mm A');
      return <div>{formattedDate}</div>;
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    width: 150,
  },
  {
    field: "comment",
    headerName: "Comment",
    width: 150,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.comment}>
          <div>{params.row.comment}</div>
        </Tooltip>
      );
    },
  },
  {
    field: "attachment",
    headerName: "Attachment",
    width: 150,
    renderCell: (params) => {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {params.row.attachment ? (
            <div>
              <a href={params.row.attachment} target="_blank" style={{textDecoration:'none',color:`${colors.brown}`,fontWeight:'bolder',border:`1px solid ${colors.brown}`,padding:'0.5em',borderRadius:'5px'}}>
                click to view
              </a>
            </div>
          ) : (
            <div>no pic</div>
          )}
        </div>
      );
    },
  },
];

export const KpiTaskTable = ({ data }) => {

  const [pageSize, setPageSize] = useState(20);

  let rows = [];

    data?.forEach((task) => {
      rows.push({
        id: task._id,
        clientName:task.clientFullName,
        clientPhone:task.clientPhone,
        currentTaskType: task?.currentTaskType,
        currentSubTypeTask: task?.currentSubTypeTask,
        completedDate: new Date(task?.completedDate),
        reason: task?.reason,
        comment: task?.comment,
        attachment: task?.attachment,
      });
    })
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      checkboxSelection
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      sx={{
        border: "none",
      }}
    />
  );
};
