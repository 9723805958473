import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Shared/colors'
import { useNavigate } from 'react-router-dom';
import { ClientsDatatable } from './ClientsDatatable';
import {
  useGetAllClientsQuery,
  useLazyGetAllClientsQuery,
} from "../../features/clientSlice";
import toast, { Toaster } from 'react-hot-toast';
import { useGetSingleHierarchyQuery, useGetStaffQuery } from '../../features/employeeSlice';
import { Autocomplete, TextField } from '@mui/material';
import { useGetFavouriteLeadsLatestQuery } from '../../features/leadsSlice';
import { Puff } from 'react-loader-spinner'

export const ClientsFirst = () => {
  let staffId = localStorage.getItem("id");
  let role = localStorage.getItem("role");
  let staffName = localStorage.getItem("staffName");
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [ClientData, setclientData] = useState({
    fullName: "",
    phoneNumber: "",
    employeeID: { id: staffId || "", label: staffName || "" },
  });
  const [ClientDatafilter, setclientDatafilter] = useState({
    fullName: "",
    phoneNumber: "",
    employeeID: { id: staffId || "", label: staffName || "" },
  });
  const { data: hierarchyStaff } = useGetSingleHierarchyQuery({ staffId });
  const { data: staff } = useGetStaffQuery();
  const { data: latestfavouriteleads, isLoading } = useGetFavouriteLeadsLatestQuery({ staffId });

  // const { data: autoClients, isFetching: isAutoFetching, error: autoError } = useGetAllClientsQuery({
  //   fullName: ClientData.fullName,
  //   phoneNumber: ClientData.phoneNumber,
  //   employeeId: ClientData.employeeID.id || staffId,
  //   page: page + 1,
  //   pageSize,
  // });

  const [trigger, { data: clients, isFetching: isLazyFetching, error: lazyError }] = useLazyGetAllClientsQuery();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setclientData({
      ...ClientData,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/leads/favourite');
  };
  const staffOptions = useMemo(() => {
    let options = [];
    if (hierarchyStaff) {
      hierarchyStaff.forEach((staff) => {
        options.push({
          id: staff?._id,
          label: staff?.firstName + " " + staff?.lastName,
        });
      });
    }
    return options;
  }, [hierarchyStaff]);

  const allStaffOptions = useMemo(() => {
    let options = [];
    if (staff) {
      staff.forEach((staff) => {
        options.push({
          id: staff?._id,
          label: staff?.firstName + " " + staff?.lastName,
        });
      });
    }
    return options;
  }, [staff]);


  useEffect(() => {
    const { fullName, phoneNumber, employeeID } = ClientDatafilter;
    if (employeeID) {
      trigger({
        employeeId: employeeID?.id || staffId,
        phoneNumber,
        fullName,
        page: currentPage + 1,
        pageSize,
      });
    }
  }, [currentPage, pageSize]);

  const handleSearch = () => {
    const { phoneNumber, employeeID, fullName } = ClientData;
    trigger({
      employeeId: employeeID?.id || staffId,
      phoneNumber,
      fullName,
      page: 1,
      pageSize: pageSize,
    })
      .then((res) => {
        toast.success("Clients loaded successfully!");
      })
      .catch((err) => {
        toast.error(err?.data?.error?.message || "Error loading clients.");
      })
      .finally(() => {
        // setclientData((prev) => ({
        //   ...prev,
        //   phoneNumber,
        //   fullName,
        // }));
        // setclientDatafilter({
        //   fullName,
        //   phoneNumber,
        // });
      });
  };


  return (
    <>
      <Main>
        <Search>
          <Heading>
            Search Client
          </Heading>
          <SearchSection1>
            <InputSection>
              <h3>Client Name:</h3>
              <input
                type="text"
                name="fullName"
                id="fullName"
                placeholder='Name'
                value={ClientData.fullName}
                onChange={handleChange}
              />
            </InputSection>
            <InputSection>
              <h3>Cell No:</h3>
              <input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                placeholder='Cell No'
                value={ClientData.phoneNumber}
                onChange={handleChange}
              />
            </InputSection>
            {(role === "ceo" || role === "admin" || role === "account") && (
              <StaffInputFeild>
                <h3>Staff:</h3>
                <Autocomplete
                  id="size-small-standard-multi"
                  size="small"
                  options={allStaffOptions}
                  getOptionLabel={(option) => option.label}
                  style={{
                    width: "93%",
                    borderRadius: "5px",
                    minHeight: "2.9em",
                    marginRight: '1em',
                    overflow: "hidden",
                    border: `1px solid ${colors.inputBorderWhite}`,
                  }}
                  onChange={(e, value) => {
                    setclientData({ ...ClientData, employeeID: value || { id: "", label: "" } });
                    setCurrentPage(0);
                    setclientDatafilter({
                      ...ClientDatafilter,
                      employeeID: value || { id: "", label: "" },
                    });
                  }}
                  value={ClientData.employeeID}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        backgroundColor: `${colors.white}`,
                        paddingTop: "1em",
                      }}
                    />
                  )}
                />
              </StaffInputFeild>
            )}
            {/* <InputSection>
          <h3>Allocation:</h3>
          <input type="text" name="allocation" id="allocation" placeholder='Choose Staff'/>
        </InputSection> */}
          </SearchSection1>
          <SearchSection2>
            {/* <InputSection>
            <h3>Staff:</h3>
            <div>
              <select name="staff" id="staffid">
                <option value="" disabled selected>Select Staff</option>
              </select>
            </div>
          </InputSection> */}
            {/* <InputSection>
          <h3>Inactive:</h3>
          <div>
          <select name="inactive" id="inactive">
            <option value="" disabled selected>any</option>
          </select>
          </div>
        </InputSection> */}
            <Button onClick={handleSearch} disabled={isLazyFetching}>
              {isLazyFetching ? <>Searching...</> : <>Search </>}
            </Button>
          </SearchSection2>
        </Search>
        <Favourite>
          <Heading>Favourite Clients</Heading>
          <FavouriteSection>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                <Puff
                  height="80"
                  width="80"
                  color={colors.brown}
                  ariaLabel="puff-loading"
                  visible={true}
                />
              </div>
            ) : (
              <>
                <FavouriteHeading>
                  <p>Client</p>
                  <p>Id</p>
                </FavouriteHeading>
                {latestfavouriteleads && latestfavouriteleads.length > 0 ? (
                  latestfavouriteleads.map((lead, index) => (
                    <FavouriteData key={index}>
                      <div>
                        <p>{lead.clientName}</p>
                        <p>{lead.totalLeads} {lead.totalLeads > 1 ? 'leads' : 'lead'}</p>
                      </div>
                      <div>
                        <p>{lead.leadID}</p>
                        <p>{new Date(lead.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                      </div>
                    </FavouriteData>
                  ))
                ) : (
                  <p>No favourite leads found.</p>
                )}
              </>
            )}
          </FavouriteSection>
          {!isLoading && <Button onClick={handleClick}>View All</Button>}
        </Favourite>
      </Main>
      <Toaster />
      <ClientsDatatable
        clients={clients}
        isAutoFetching={isLazyFetching}
        currentPage={currentPage}
        totalPages={Math.ceil(clients?.total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setCurrentPage}
      />
    </>

  )
}

const Main = styled.div`
width: 90%;
display: flex;
justify-content: space-between;
align-items: center;
min-height: 30vh;
`

const Search = styled.div`
background-color: ${colors.inputWhite};
width: 65%;
box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
border-radius: 5px;
min-height: 19em;
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 1em;
`

const Heading = styled.h4`
height: 30%;
width: 100%;
color: ${colors.text};
font-size: clamp(1em,1.5vw,2em);
border-bottom: 1px solid ${colors.inputBorderWhite};
padding-bottom: 1em;
`

const SearchSection1 = styled.div`
height: 30%;
display: flex;
gap: 20px;
// justify-content: space-between;
`

const SearchSection2 = styled.div`
height: 30%;
display: flex;
justify-content: end;
align-items: flex-end;
`

const Favourite = styled.div`
background-color: ${colors.inputWhite};
width: 30%;
box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
border-radius: 5px;
min-height: 19em;
padding: 1em;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
`

const FavouriteSection = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
width: 100%;
`

const FavouriteHeading = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
p{
  padding: 1em 0em;
  width: 40%;
  font-size: clamp(1em,1vh,2em);
}
`

const FavouriteData = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
div{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 3em;
  p{
    font-size: clamp(1em,1vh,2em);
    :nth-child(1){
      color: ${colors.textBlue};
    }
  }
}
`

const InputSection = styled.div`
min-width: 2em;
width: 12vw;
h3{
  color: ${colors.black};
  font-size: clamp(1em,1vw,2em);
  margin: 1em 0em;
}
div{
  position: relative;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  width: 100%;
  padding-right: 1em;
  background-color: ${colors.white};
  select{
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  border: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
}
input{
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
`

const Button = styled.button`
padding: 1em 2em;
color: ${colors.white};
background-color: ${colors.brown};
border: none;
border-radius: 5px;
cursor: pointer;
`
const StaffInputFeild = styled.div`
  width: 20em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;