// import React from 'react'
// import styled from 'styled-components'
// import { colors } from '../../../Shared/colors'

// export const ManageLeadsFirst = () => {
//   return (
//     <Main>
//         <Heading>
//             Add Client Information
//         </Heading>
//         <InputSection>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>Lead ID:</h3>
//                     <input type="text" name="leadId" id="leadId" />
//                 </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>ID:</h3>
//                     <input type="text" name="Id" id="Id" />
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Date Added:</h3>
//                             <div>
//                                 <input type="date" name="dateAdded" id="dateAdded" />
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>Name:</h3>
//                     <input type="text" name="name" id="name" />
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Project:</h3>
//                             <div>
//                             <select name="project" id="project">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>National ID:</h3>
//                     <input type="text" name="nationalId" id="nationalId" />
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>Allocation:</h3>
//                     <input type="text" name="allocation" id="allocation" />
//                 </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>Landline:</h3>
//                     <input type="text" name="landline" id="Landline" />
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Status:</h3>
//                             <div>
//                             <select name="status" id="status">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>Cell:</h3>
//                     <input type="text" name="cell" id="cell" />
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Allocated Date:</h3>
//                             <div>
//                                 <input type="date" name="allocatedDate" id="allocatedDate" />
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>Occupation:</h3>
//                     <input type="text" name="occupation" id="occupation" />
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Classification:</h3>
//                             <div>
//                             <select name="classification" id="classification">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Country Type:</h3>
//                             <div>
//                             <select name="countryType" id="countryType">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Shared With:</h3>
//                             <div>
//                             <select name="sharedWith" id="sharedWith">
//                                 <option value="" disabled selected>Choose Staff</option>
//                             </select>
//                             </div>
//                 </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Buying Intent:</h3>
//                             <div>
//                             <select name="buuingIntent" id="BuyingIntent">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Date Shared:</h3>
//                             <div>
//                                 <input type="date" name="dateShared" id="dateShared" />
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Client Type:</h3>
//                             <div>
//                             <select name="clientType" id="clientType">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                 </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Source:</h3>
//                             <div>
//                             <select name="source" id="source">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//                 <RowPart>
//                 <InputFeild>
//                             <h3>Client Rating:</h3>
//                             <div>
//                             <select name="clientRating" id="clientRating">
//                                 <option value="" disabled selected>Select</option>
//                             </select>
//                             </div>
//                         </InputFeild>
//                 </RowPart>
//             </Row>
//             <Row>
//                 <RowPart>
//                 <InputFeild>
//                     <h3>Occupation:</h3>
//                     <input type="text" name="occupation" id="occupation" />
//                 </InputFeild>
//                 </RowPart>
//                 <RowPart3>
//                     <div>
//                         <InputFeild>
//                             <h3>Income/Month:</h3>
//                             <input type="text" name="income/month" id="income/month" />
//                         </InputFeild>
//                     </div>
//                     <div>
//                         <InputFeild>
//                         <h3>Min:</h3>
//                         <input type="text" name="min" id="min" />
//                         </InputFeild>
//                     </div>
//                     <div>
//                         <InputFeild>
//                             <h3>Max:</h3>
//                             <input type="text" name="max" id="max" />
//                         </InputFeild>
//                     </div>
//                 </RowPart3>
//             </Row>
//         </InputSection>
//     </Main>
//   )
// }

// const Main = styled.div`
// width: 90%;
// margin-top: 2em;
// background-color: ${colors.white};
// min-height: 60vh;
// padding: 1em;
// display: flex;
// flex-direction: column;
// align-items: center;
// `


// const Heading = styled.div`
// font-size: clamp(1.2em,1.2vw,2.2em);
// width: 100%;
// margin-top: 1em;
// `
// const InputSection = styled.div`
// width: 100%;
// display: flex;
// flex-direction: column;
// align-items: center;
// `

// const Row = styled.div`
// display: flex;
// justify-content: space-between;
// width: 100%;
// `

// const RowPart = styled.div`
// width: 45%;
// `

// const RowPart3= styled.div`
// width: 45%;
// display: flex;
// justify-content: space-between;
// `
// const InputFeild = styled.div`
// width: 100%;
// h3{
//   color: ${colors.black};
//   font-size: clamp(1em,1vw,2em);
//   margin: 1em 0em;
// }
// div{
//   position: relative;
//   border: 1px solid ${colors.inputBorderWhite};
//   border-radius: 6px;
//   width: 100%;
//   padding-right: 1em;
//   background-color: ${colors.inputWhite};
//   select,input[type=date]{
//   background-color: ${colors.inputWhite};
//   width: 100%;
//   padding: 1em 0em 1em 1em;
//   outline: none;
//   border: none;
//   ::placeholder{
//     color: ${colors.inputFeild};
//   }
// }
// }
// input{
//   border: 1px solid ${colors.inputBorderWhite};
//   border-radius: 6px;
//   background-color: ${colors.inputWhite};
//   width: 100%;
//   padding: 1em 0em 1em 1em;
//   outline: none;
//   ::placeholder{
//     color: ${colors.inputFeild};
//   }
// }
// `

import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { colors } from "../../../Shared/colors";
import {
  useGetSingleHierarchyQuery,
  useGetStaffQuery,
} from "../../../features/employeeSlice";
import { useGetAllProjectsQuery } from "../../../features/projectSlice";
import { useGetAllClientsQuery } from "../../../features/clientSlice";
import { useLazySearchLeadsQuery } from "../../../features/leadsSlice";
import { toast, Toaster } from 'react-hot-toast'
import { ManageLeadsDataTable } from "./ManageLeadsDataTabel";
export const ManageLeadsFirst = () => {
  let staffId = localStorage.getItem("id");
  let staffName = localStorage.getItem("staffName");
  let role = localStorage.getItem("role");
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);

  // const { data: hierarchyStaff } = useGetHierarchysubordinatesQuery(staffId);
  
  const { data: hierarchyStaff } = useGetSingleHierarchyQuery({ staffId });
  const { data: staff } = useGetStaffQuery();


  const { data: clients } = useGetAllClientsQuery({employeeId: staffId});

  const [trigger, { data: leadData, isFetching, error }] =
    useLazySearchLeadsQuery()

  const { data: project } = useGetAllProjectsQuery();

 
  const staffOptions = useMemo(() => {
    let options = [];
    hierarchyStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [hierarchyStaff]);

  
  const allStaffOptions = useMemo(() => {
    let options = [];
    staff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [staff]);

  const clientOptions = useMemo(() => {
    let options = [];
    clients?.LoginEmployeeClients.map((client) =>
      options.push({
        id: client?._id,
        label: client?.fullName,
      })
    );
    return options;
  }, [clients]);

  const [kpiData, setkpiData] = useState({
    // start: '',
    // end: defaultDate,
    // clientID: clientOptions[0],
    classification: "",
    source: "",
    numTasks: "",
    leadID: "",
    from: "",
    employeeID: { id: staffId, label: staffName },
    project: "",
  });
  const [searchFilters, setSearchFilters] = useState({
    projectID: "",
    classification: "",
    source: "",
    numTasks: "",
    leadID: "",
    employeeID: staffId,
    from: "",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setkpiData({
      ...kpiData,
      [name]: value,
    });
  };

  useEffect(() => {
    const { projectID, classification, source, numTasks, leadID, from, employeeID } = searchFilters;
    trigger({
      projectID,
      employeeID,
      from,
      classification,
      source,
      numTasks,
      leadID,
      page: currentPage + 1,
      limit: pageSize
    });
  }, [currentPage, pageSize, searchFilters]);

  const handleSearch = () => {
    const { project, classification, source, numTasks, leadID, from, employeeID } = kpiData;
    trigger({
      projectID: project,
      employeeID: employeeID?.id || staffId,
      from,
      classification,
      source,
      numTasks,
      leadID,
      page: 1,
      limit: pageSize
    })
      .then((res) => {
        toast.success("Leads loaded successfully!");
      })
      .catch((err) => {
        toast.error(err.data.error.message);
      })
      .finally(() => {
        setkpiData((prev) => ({
          classification: "",
          source: "",
          numTasks: "",
          leadID: "",
          employeeID: prev.employeeID,
          project: "",
          from: "",
        }));
      });
    setSearchFilters({
      projectID: project,
      employeeID: employeeID?.id || staffId,
      classification,
      source,
      numTasks,
      leadID,
      from
    });
  };

  return (
    <>
      <Main>
        <Search>
          <SubHeading>Manage Leads</SubHeading>
          <SearchSection1>
            {/* <InputSection>
              <h3>Start:</h3>
              <div>
                <input
                  type="date"
                  name="start"
                  id="start"
                  value={kpiData.start}
                  onChange={handleChange}
                />
              </div>
            </InputSection>
            <InputSection>
              <h3>End:</h3>
              <div>
                <input
                  type="date"
                  name="end"
                  id="end"
                  value={kpiData.end}
                  onChange={handleChange}
                />
              </div>
            </InputSection> */}
            <InputSection>
              <h3>Classification:</h3>
              <div>
                <select
                  name="classification"
                  id="classification"
                  value={kpiData.classification}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="veryHot">Very Hot</option>
                  <option value="hot">Hot</option>
                  <option value="moderate">Moderate</option>
                  <option value="cold">Cold</option>
                  <option value="veryCold">Very Cold </option>
                </select>
              </div>
            </InputSection>
            <InputSection>
              <h3>Source:</h3>
              <div>
                <select
                  name="source"
                  id="source"
                  value={kpiData.source}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="walkIn">Walk In</option>
                  <option value="Facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="reference">Refrence</option>
                  <option value="billboards">Bill Board</option>
                </select>
              </div>
            </InputSection>
            <InputSection>
              <h3>Project:</h3>
              <div>
                <select
                  name="project"
                  id="project"
                  value={kpiData.project}
                  onChange={handleChange}
                >
                  <option value="">select</option>
                  {project?.map((proj) => (
                    <option value={proj?._id}>{proj.projectName}</option>
                  ))}
                </select>
              </div>
            </InputSection>
          </SearchSection1>
          <SearchSection2>
            <InputSection>
              <h3>From:</h3>
              <div>
                <select name="from" id="from" onChange={handleChange} value={kpiData.from}>
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                </select>
              </div>
            </InputSection>
            <InputSection>
              <h3>Num Of Tasks:</h3>
              <div>
                <select
                  name="numTasks"
                  id="numTasks"
                  value={kpiData.numTasks}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="moreThanTwo">More Than Two</option>
                </select>
              </div>
            </InputSection>
            {/* <StaffInputFeild>
              <h3>Client:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                options={clientOptions}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  setkpiData({ ...kpiData, clientID: value });
                }}
                value={kpiData.clientID}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild> */}

            <StaffInputFeild>
              <h3>Staff:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                options={role === "ceo" || role === "admin" || role === "account" ? allStaffOptions :  staffOptions}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "93%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  marginRight: '1em',
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  setkpiData({ ...kpiData, employeeID: value });
                }}
                value={kpiData.staff}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild>
            <Button onClick={handleSearch} disabled={isFetching}>
              {isFetching ? <>Searching...</> : <>Search</>}
            </Button>
          </SearchSection2>
        </Search>
      </Main>
      <Toaster />
      <ManageLeadsDataTable
        leads={leadData}
        isFetching={isFetching}
        currentPage={currentPage}
        totalPages={Math.ceil(leadData?.total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={setCurrentPage}
      />
    </>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  font-size: clamp(1.2em, 1.2vw, 1.5em);
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  width: 67%;
  max-width: 25em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 95%;
    padding-right: 1em;
    background-color: ${colors.white};

    select,
    input[type="date"],
    input[type="text"] {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 90%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  min-width: 10em;
  cursor: pointer;
`;

const StaffInputFeild = styled.div`
  width: 20em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;
