import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  useGetStaffQuery,
  useHierarchyCreateMutation,
  useHierarchyUpdateMutation,
} from "../../../features/employeeSlice";
import { toast, Toaster } from "react-hot-toast";

export const TeamHerarchyFirst2 = () => {
  const [updateHierarchy, {isLoading}] = useHierarchyUpdateMutation()

  const { data: employee } = useGetStaffQuery();

  const [teamData, setteamData] = useState({
    employeeId: "",
    managerId: "",
    subordinateIds: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setteamData({ ...teamData, [name]: value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    let subordinateIds = [];
    teamData.subordinateIds.map((sub)=>(
        subordinateIds.push(sub.id)
    ))
    let data = {}
    if(teamData.managerId===""){
      data = {
        employeeId:teamData.employeeId,
        subordinateIds: subordinateIds,
      };
    }
    else if(teamData.subordinateIds.length===0){
      data = {
        employeeId:teamData.employeeId,
        managerId: teamData.managerId,
      };
    }
    else{
      data = {
        employeeId:teamData.employeeId,
        managerId: teamData.managerId,
        subordinateIds: subordinateIds,
      };
    }
    updateHierarchy({data})
      .unwrap()
      .then((res) => {
        toast.success("Hierarchy Updated successfully");
        setteamData({
            employeeId: "",
            managerId: "",
            subordinateIds: [],
          });
      })
      .catch((err) => {
        toast.error("Error while updating Hierarchy");
        setteamData({
            employeeId: "",
            managerId: "",
            subordinateIds: [],
          });
      });
  };

  let subordinates = useMemo(() => {
    let options = [];
    employee?.forEach((emp) => {
      options.push({
        name: emp.firstName + " " + emp.lastName,
        id: emp._id,
      });
    });
    return options;
  }, [employee]);

  return (
    <Main>
      <Search>
        <SubHeading>Update Team Hierarchy</SubHeading>
        <SearchSection1>
          <InputSection>
            <h3>Employee:</h3>
            <div>
              <select name="employeeId" onChange={handleChange}>
                <option value="">Select</option>
                {employee?.map((emp) => (
                  <option value={emp._id}>
                    {emp.firstName} {emp.lastName}
                  </option>
                ))}
              </select>
            </div>
          </InputSection>
          <SubOrdinates>
            <h3>Sub ordinates:</h3>
            <Autocomplete
              multiple
              id="size-small-standard-multi"
              size="small"
              options={subordinates}
              getOptionLabel={(option) => option.name}
              style={{
                width: "100%",
                borderRadius: "5px",
                minHeight: "2.9em",
                overflow: "hidden",
                border: `1px solid ${colors.inputBorderWhite}`,
              }}
              onChange={(e, value) => {
                setteamData({ ...teamData, subordinateIds: value });
              }}
              value={teamData.subordinateIds}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{ backgroundColor: `${colors.white}`, paddingTop: "1em" }}
                />
              )}
            />
          </SubOrdinates>
        </SearchSection1>
        <SearchSection2>
          <Button onClick={handleSave} disabled={isLoading}>
            {isLoading ? <>Updating...</> : <>Update</>}
          </Button>
        </SearchSection2>
      </Search>
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
  margin-top: 3em;
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  font-size: clamp(1.2em, 1.2vw, 1.5em);
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  min-width: 2em;
  width: 30vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  min-width: 8em;
  margin-left: auto;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const SubOrdinates = styled.div`
  width: 30vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  {
    title: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  {
    title: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];
