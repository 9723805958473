import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../Shared/colors'

export const ManageLeadsSecond = () => {
  return (
    <Main>
      <Section>
        <Heading>
          Add Client Information
        </Heading>
        <InputFeild>
          <h3>Last Updated:</h3>
          <div>
            <select name="lastUpdated" id="lastUpdated">
              <option value="" disabled selected>Select</option>
            </select>
          </div>
        </InputFeild>
        <InputFeild>
          <h3>QC Status:</h3>
          <div>
            <select name="QcStatus" id="QcStatus">
              <option value="" disabled selected>Select</option>
            </select>
          </div>
        </InputFeild>
        <InputFeild>
          <h3>Task Date Added:</h3>
          <div>
            <input type="date" name="taskDateAdded" id="taskDateAdded" />
          </div>
        </InputFeild>
      </Section>
      <Section>
        <Heading>
          Add Client Information
        </Heading>
        <InputFeild>
          <h3>No. of Task:</h3>
          <div>
            <select name="noOfTask" id="noOfTask">
              <option value="" disabled selected>Select</option>
            </select>
          </div>
        </InputFeild>
        <InputFeild>
          <h3>Task Added By:</h3>
          <div>
            <select name="taskAddedBy" id="taskAddedBy">
              <option value="" disabled selected>Select</option>
            </select>
          </div>
        </InputFeild>
        <InputFeild>
          <Button>
            Search
          </Button>
        </InputFeild>
      </Section>
    </Main>
  )
}


const Main = styled.div`
width: 90%;
margin-top: 2em;
background-color: ${colors.white};
min-height: 30vh;
padding: 1em;
display: flex;
justify-content: space-between;
align-items: center;
`

const Section = styled.div`
width: 45%;
display: flex;
flex-direction: column;
justify-content: space-between;
`

const Heading = styled.div`
height: 30%;
width: 100%;
color: ${colors.text};
font-size: clamp(1em,1vw,1.5em);
border-bottom: 1px solid ${colors.inputBorderWhite};
padding-bottom: 1em;
`
const InputFeild = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
min-height: 7em;
h3{
  color: ${colors.black};
  font-size: clamp(1em,1vw,2em);
  margin: 1em 0em;
}
div{
  position: relative;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  width: 100%;
  padding-right: 1em;
  background-color: ${colors.inputWhite};
  select,input[type=date]{
  background-color: ${colors.inputWhite};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  border: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
}
input{
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  background-color: ${colors.inputWhite};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
`

const Button = styled.button`
padding: 1em 2em;
width: 10em;
color: ${colors.white};
background-color: ${colors.brown};
border: none;
border-radius: 5px;
cursor: pointer;
`