import React, { useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../../Shared/colors";
import {
  useGetSingleHierarchyQuery,
  useGetStaffQuery,
} from "../../../features/employeeSlice";
import { TeamTable } from "./TeamTable";
import ExelPopup from "./ExelPopup";

export const ManageTeamFirst = () => {
  const [pageSize, setPageSize] = useState(10);
  const [teamPopUp, setTeamPopUp] = useState(false)
  const [teamExelPopUp, setTeamExelPopUp] = useState(false)
  const [teamId, setteamId] = useState('')

  const staffId = localStorage.getItem("id");

  const { data: staff, isFetching } = useGetSingleHierarchyQuery({ staffId });

  const columns = [
    {
      field: "empName",
      headerName: "EMP. NAME",
      width: 150,
    },
    // {
    //   field: "cnic",
    //   headerName: "Cnic",
    //   width: 150,
    // },
    {
      field: "desig",
      headerName: "DESIG",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
    },
    {
      field: "assignClients",
      headerName: "Assign Clients",
      width: 200,
      renderCell: (params) => {
        return (
          <Action>
            <Assignbutton
            onClick={() => {
                setTeamPopUp(true);
                setteamId(params.row.id);
              }}
              >Assign Clients</Assignbutton>
          </Action>
        );
      },
    },
    {
      field: "assignExelClients",
      headerName: "Assign Clients Exel",
      width: 200,
      renderCell: (params) => {
        return (
          <Action>
            <Assignbutton
            onClick={() => {
                setTeamExelPopUp(true);
                setteamId(params.row.id);
              }}
              >Assign Clients</Assignbutton>
          </Action>
        );
      },
    },
    // {
    //   field: 'action',
    //   headerName: 'Actions',
    //   width: 80,
    //   sortable: false ,
    //   filterable:false,
    //   hideable:false,
    //   renderCell:()=>{
    //     return(
    //       <Action>
    //         <EditIcon style={{color:`${colors.textBlue}`,cursor:'pointer'}}/>
    //       </Action>
    //     )
    //   }
    // },
  ];

  const rows = [];
  staff?.forEach((staff) => {
    rows.push({
      id: staff._id,
      cnic: staff.cnic,
      empId: staff.empID,
      empName: staff.firstName + " " + staff.lastName,
      phone: staff.phoneNum,
      phone: staff.phoneNum,
      desig: staff.designation,
      city: staff.address,
    });
  });

  return (
    <Main>
      <LeadsSection>
        <div>
          Total Team:<span> {staff?.subordinates?.length} </span>
        </div>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isFetching}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <PopUpContainer popUp={teamPopUp}>
        <PopUp>
          <Cross
            onClick={() => setTeamPopUp(!teamPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <TeamTable teamId={teamId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={teamExelPopUp}>
        <PopUp style={{
          width:"50%",
          height:"40%",
        }}>
          <Cross
            onClick={() => setTeamExelPopUp(!teamExelPopUp)}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <ExelPopup teamId={teamId}/>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.textMed};
    padding: 1em;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
    cursor: pointer;
    font-size: clamp(0.8em, 0.9vw, 1em);
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Assignbutton = styled.button`
background-color: ${colors.brown};
color: ${colors.white};
padding: 0.5em 1em;
border: none;
border-radius: 5px;
cursor: pointer;
`;


const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 90%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
