import io from "socket.io-client";

// const socket = io("http://localhost:5056/");
// const socket = io("https://jagiir-propware-staging.onrender.com");
const socket = io("https://propware-jagiir.onrender.com/");
// const socket = io("http://192.168.100.24:5056/");

/**
 * Setup socket connection and handle 'connect' event.
 */
const setupSocket = () => {
  socket.on("connect", () => {
  });
};

/**
 * Disconnect from the socket.
 */
const socketDisconnect = () => {
  socket.disconnect();
};

/**
 * Subscribe to 'newNotification' events.
 * @param {Function} callback - Callback function to handle new notifications.
 */
const subscribeToNotifications = (callback) => {
  socket.on("newNotification", callback);
};

/**
 * Unsubscribe from 'newNotification' events.
 */
const unsubscribeFromNotifications = () => {
  socket.off("newNotification");
};

/**
 * Add a new employee.
 * @param {string} empId - Employee ID.
 * @param {string} fullName - Full name of the employee.
 * @param {Object} leadDetails - Object containing details of the assigned lead
 */
const addEmp = (empId, fullName) => {
  socket.emit("addEmp", { empId, fullName });
};

const assignLeadNotification = (data) => {
  socket.emit("leadAssign", data);
}

const unSubLeadAssigned = () => {
  socket.off("leadAssign");
};

/** 
 * Notify about a new policy.
 * @param {string} newMessage - Message about the new policy.
 */
// const newPolicy = (newMessage) => {
//   socket.emit("policyNotification", { newMessage });
// };

/**
 * Unsubscribe from 'policyNotification' events.
 */
const unSubNewPolicy = () => {
  socket.off("policyNotification");
};

/**
 * Fetch notifications for a specific employee.
 * @param {string} empId - Employee ID.
 */
const postgetNotify = (empId) => {
  socket.emit("getNotify", { empId });
};

/**
 * Unsubscribe from 'getNotify' events.
 */
const unsubPostGetNotify = () => {
  socket.off("getNotify");
};

/**
 * Get the count of notifications.
 * @param {Function} callback - Callback function to handle notification count.
 */
const notificationCount = (callback) => {
  socket.on("notificationCount", callback);
};

const duplicateEmployee = (empId, type, message) => {
  socket.emit("searchClientNotification", { empId, type, message })
}

const broadcastPolicyMessage = (callback) => {
  socket.on("broadcastPolicyMessage", callback);
};

const broadcastAssignLeadMessage = (callback) => {
  socket.on("leadAssigned", callback);
};
const broadcastPaymentInitiateMessage = (callback) => {
  socket.on("paymentInitiation", callback);
};
const broadcastPaymentVerificationMessage = (callback) => {
  socket.on("paymentVerificationNotification", callback);
};

const meetingNotification = (callback) => {
  socket.on("meetingNotification", callback);
}
const feedbackNotification = (callback) => {
  socket.on("newFeedbackOnLead", callback);
}
export {
  setupSocket,
  socketDisconnect,
  subscribeToNotifications,
  unsubscribeFromNotifications,
  addEmp,
  feedbackNotification,
  // newPolicy,
  assignLeadNotification,
  unSubLeadAssigned,
  unSubNewPolicy,
  postgetNotify,
  notificationCount,
  unsubPostGetNotify,
  duplicateEmployee,
  broadcastPolicyMessage,
  broadcastAssignLeadMessage,
  meetingNotification,
  broadcastPaymentInitiateMessage,
  broadcastPaymentVerificationMessage
};