import React,{useState} from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import DescriptionIcon from '@mui/icons-material/Description';
import { colors } from '../../../Shared/colors';
import { useGetAllProjectsQuery } from '../../../features/projectSlice';

export const ProjectsDataTable = () => {
  const [pageSize, setPageSize] = useState(20);

let Inventory=null
let {data:Projects} = useGetAllProjectsQuery()

let isLoading= false
  const columns = [
    {
      field:'name',
      headerName:'Name',
      width:200,
    },
    {
      field:'type',
      headerName:'Type',
      width:200
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 200,
    },
  ];
  let rows = [];
  Projects?.forEach(project => {
    rows.push({
      id:project._id,
      name:project.projectName,
      type:project.projectType,
      region:project.region.regionName
    })
  });
  return (
    <Main>
      <LeadsSection>
      Projects:<span> {Projects?.length}</span> 
      </LeadsSection>
      <Table>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        loading={isLoading}
        sx={{
          border:'none'
        }}
      />
      </Table>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 3em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`

const LeadsSection = styled.p`
font-size: clamp(1.5em,1.5vh,2em);
padding: 0.5em 0em;
height: 10%;
span{
  font-weight: bold;
}
`

const Table = styled.div`
height: 90%;
`


const Contracted = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
div{
width: 60%;
background-color: ${props=>props.color};
color: white;
padding: 0.5em 0.2em;
border-radius: 5px;
text-align: center;
}
`

const Action = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
div{
  width: 20px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
}
`