import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Shared/colors'
import { NavLink } from 'react-router-dom'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export const ClientsSidebar = () => {
    return (
        <>
            <Section>
                <p>
                    Leads
                </p>
                <div>
                    <NavLink end to='clients'>
                        <SearchOutlinedIcon /> <span>Clients</span>
                    </NavLink>
                </div>
            </Section>
        </>
    )
}


const Section = styled.div`
display: flex;
flex-direction: column;
p{
    color: ${colors.textLight};
    font-size: clamp(0.9em,0.9vh,2em);
    margin-top: 1em;
}
div{
    display: flex;
    flex-direction: column;
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: ${colors.textMed};
        width: 100%;
        padding: 1em 0;
        font-size: clamp(1em,1vh,2em);
        margin-top: 1em;
        border-radius: 5px;
        transition: all 0.3s ease-out;
        position: relative;
        ::before{
            content: '';
            position: absolute;
            left: -48px;
            width: 20px;
            height: 100%;
            background-color: ${colors.brown};
            border-radius: 5px;
            display: none;
        }
        :hover{
            background-color: ${colors.inputWhite};
            color: ${colors.brown};
            ::before{
                display: block;
            }
        }
        
        span{
            width: 50%;
            margin-left: 5%;
        }
    }
    .active{
            background-color: ${colors.inputWhite};
            color: ${colors.brown};
            ::before{
                display: block;
            }
        }
}
`