import React ,{useState}from 'react'
import { DataGrid } from "@mui/x-data-grid";
import { useGetCompletedTaskByTaskIdQuery } from '../../../features/taskSlice';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment-timezone';

const columns =[
    {
      field: "client",
      headerName: "Client",
      width: 150,
      renderCell:(params)=>{
        return(
          <Tooltip title={params.row.client}>
            <div>
            {params.row.client}
            </div>
          </Tooltip>
        )
      }
    },
    {
      field: "cellNo",
      headerName: "Cell No",
      width: 150,
      renderCell:(params)=>{
        return(
          <div>
            {params.row.cellNo}
          </div>
        )
      }
    },
    // {
    //   field: "currentTaskType",
    //   headerName: "Task Type",
    //   width: 150,
    // },
    // {
    //   field: "currentSubTypeTask",
    //   headerName: "Task Sub Type",
    //   width: 150,
    // },
    // {
    //   field: "completedDate",
    //   headerName: "Completed Date",
    //   width: 150,
    //   renderCell: (params) => {
    //     let date = params.row.completedDate;
    //     return <div>{date.toUTCString()}</div>;
    //   },
    // },
    {
      field: "completedDate",
      headerName: "Completed Date",
      width: 200,
      renderCell: (params) => {
        let date = moment(params.row.completedDate).tz('Asia/Karachi');
        let formattedDate = date.format('ddd, MMM D, YYYY h:mm A');
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 150,
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 150,
      renderCell:(params)=>{
        return(
          <Tooltip title={params.row.comment}>
            <div>
            {params.row.comment}
            </div>
          </Tooltip>
        )
      }
    },{
      field: "empName",
      headerName: "Employee Name",
      width: 200,
    },
  ]

export const TaskTable = ({taskId}) => {

    const {data:taskData,isFetching} = useGetCompletedTaskByTaskIdQuery(taskId)
    
    const [pageSize, setPageSize] = useState(5);
    

    let rows=[];
    if(taskData){
    let client= taskData[0]?.client.fullName
    let cellNo= taskData[0]?.client?.cellNo[0]?.phoneNo

    taskData[0]?.currentTask.forEach(task => {
      rows.push({
          id:task._id,
          client:client,
          cellNo:cellNo,
          currentTaskType:task?.currentTaskType,
          currentSubTypeTask:task?.currentSubTypeTask,
          completedDate:new Date(task?.completedDate),
          reason:task?.reason,
          comment:task?.comment,
          empName:task?.empName
      })
  });
    }
  return (
    <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              loading={isFetching}
              sx={{
                border: "none",
              }}
    />
  )
}
