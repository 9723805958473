import React, { useMemo ,useState} from "react";
import styled from "styled-components";
import { useGetHierarchyQuery } from "../../../features/employeeSlice";
import { colors } from "../../../Shared/colors";
import { HierarchyDatatable } from "./HierarchyDatatable";
import TeamTreeGraph from "./TeamTreeGraph";

const TeamHierarchySecond = () => {

  const [tableData, settableData] = useState(null)

  const setTableData = (data) =>{
    settableData(data)
  }


  const { data: hierarchy } = useGetHierarchyQuery();

  return (
    <>
    <Main>
      <TeamTreeGraph data={hierarchy} setTableData={setTableData}/>
    </Main>
    <HierarchyDatatable tableData={hierarchy}/>
    </>
  );
};

export default TeamHierarchySecond;

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 60vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
