import React from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import { NavLink } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { name } from "../../Shared/config";

export const StaffSidebar = () => {
  const role = localStorage.getItem("role");
  return (
    <>
      <Section>
        <p>Staff</p>
        <div>
          <NavLink end to="staff">
            <AccountCircleIcon />
            <span>{name} Contact</span>
          </NavLink>
            <>
              <NavLink end to="staff/manageStaff">
                <ManageAccountsIcon /> <span>Manage Staff</span>
              </NavLink>

              <NavLink ent to="staff/manageTeamHerarchy">
                <ManageAccountsIcon /> <span>Team Hierarchy</span>
              </NavLink>
            </>
          {/* ) : null} */}
          <NavLink end to="staff/manageTeam">
            <ManageAccountsIcon /> <span>Manage Team</span>
          </NavLink>
        </div>
      </Section>
    </>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: ${colors.textLight};
    font-size: clamp(0.9em, 0.9vh, 2em);
    margin-top: 1em;
  }
  div {
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: ${colors.textMed};
      width: 100%;
      padding: 1em 0;
      font-size: clamp(1em, 1vh, 2em);
      margin-top: 1em;
      border-radius: 5px;
      transition: all 0.3s ease-out;
      position: relative;
      ::before {
        content: "";
        position: absolute;
        left: -48px;
        width: 20px;
        height: 100%;
        background-color: ${colors.brown};
        border-radius: 5px;
        display: none;
      }
      :hover {
        background-color: ${colors.inputWhite};
        color: ${colors.brown};
        ::before {
          display: block;
        }
      }

      span {
        width: 50%;
        margin-left: 5%;
      }
    }
    .active {
      background-color: ${colors.inputWhite};
      color: ${colors.brown};
      ::before {
        display: block;
      }
    }
  }
`;
