import React from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { useGetDashboardReportQuery, useGetTodayPendingTaskQuery } from "../../../features/employeeSlice";

const Colors = [colors.hot,colors.moderate,colors.textBlue];


export const PendingTask = ({staff}) => {
  // const { data: Task } = useGetTodayPendingTaskQuery();

  const { data: dashboard } = useGetDashboardReportQuery({staffId: staff?.id});
  const data = [
    {
      name: "One Task",
      uv: dashboard?.taskReport?.oneTask,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Two Tasks',
      uv: dashboard?.taskReport?.twoTasks,
      pv: 1398,
      amt: 2210,
    },
    {
      name: '< Two Tasks',
      uv: dashboard?.taskReport?.moreThanTwoTasks,
      pv: 9800,
      amt: 2290,
    }
  ];

  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
    Z`;
  };
  
  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
  
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };
  return (
    <Main>
      <p>Task Report</p>
      <Graph>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar
              dataKey="uv"
              fill="#8884d8"
              shape={<TriangleBar />}
              label={{ position: "top" }}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={Colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Graph>
    </Main>
  );
};

const Main = styled.div`
  width: 40%;
  min-width: 30em;
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: ${colors.inputWhite};
  margin-top: 3em;
  padding: 1em;
  p {
    font-size: clamp(1em, 3vh, 4em);
    margin-bottom: 1em;
  }
`;
const Graph = styled.div`
  width: 100%;
  height: 80%;
`;
