import { apiSlice } from "./api/apiSlice";

const accountSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDownPayment: builder.query({
            query: (body) => ({
                url: `/downPayment/status?status=${body}`
            }),
            transformResponse: (response) => response.downPaymentStatus,
            provideTags: ['Account']
        }),
        getTokenPayment: builder.query({
            query: ({ page, limit, paymentStatus, paymentType, clientId, leadId, projectId }) => {
                const queryParams = new URLSearchParams();

                if (page) queryParams.append("page", page);
                if (limit) queryParams.append("limit", limit);
                if (paymentStatus) queryParams.append("paymentStatus", paymentStatus);
                if (paymentType) queryParams.append("paymentType", paymentType);
                if (clientId) queryParams.append("clientId", clientId);
                if (leadId) queryParams.append("leadId", leadId);
                if (projectId) queryParams.append("projectId", projectId);

                return {
                    url: `/account/payment-info?${queryParams.toString()}`,
                };
            },
            transformResponse: (response) => response,
            providesTags: ['Account'],
        }),
        Paymentverify: builder.mutation({
            query: ({ id, body }) => ({
                url: `/account/payment/${id}/status`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Account"],
        }),
        Paymentreupload: builder.mutation({
            query: ({ id, body }) => ({
                url: `/account/payment/${id}/reupload`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Account"],
        }),
        getTokens: builder.query({
            query: () => ({
                url: `/tokenPayment`
            }),
            transformResponse: (response) => response.token,
            provideTags: ['Account']
        }),
    })
})


export const { useGetDownPaymentQuery, useGetTokenPaymentQuery, useGetTokensQuery, usePaymentverifyMutation, usePaymentreuploadMutation } = accountSlice;