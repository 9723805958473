import React from "react";
import styled from "styled-components";
import { useSelectedStaffContext } from "../../context/selectedStaff";
import { HierarchyDatatable } from "./HierarchyDatatable";
import { OverdueTable } from "./OverdueTable";

const SelectedStaff = () => {

    const {selectedStaff,selectedStaffOverdue} = useSelectedStaffContext()
  return (
    <Main>
      <HierarchyDatatable tableData={selectedStaff?.leads} name={selectedStaff?.employeeName}/>
      <OverdueTable overdueTasks={selectedStaffOverdue} name={selectedStaff?.employeeName}/>
      {/* <GraphSection>
        <SubHeading>Sales</SubHeading>
        <KpiContainer>
          <KpiBullets>
            <ul>
              {salesData?.map((call) => (
                <li>
                  {call.name}: <span>{call.count}</span>
                </li>
              ))}
            </ul>
          </KpiBullets>
          <Graph>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={400}
                data={salesData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill={colors.brown} />
              </BarChart>
            </ResponsiveContainer>
          </Graph>
        </KpiContainer>
      </GraphSection> */}
    </Main>
  );
};

export default SelectedStaff;

const Main = styled.div`
  width: 90%;
`;
