import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import styled from 'styled-components'
import { colors } from '../../../Shared/colors'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useGetCalenderQuery } from '../../../features/leadsSlice'

const localizer = momentLocalizer(moment)

export const CalendarFirst = () => {

  const { data: calender } = useGetCalenderQuery()

  const myEventsList = []
  calender?.forEach(cal => {
    myEventsList.push({
      'title': cal.nextTask?.nextTaskType,
      'Lead ID': cal?.leadID?.leadID,
      'start': new Date(cal.nextTask?.startDate),
      'end': new Date(cal.nextTask?.deadline),
    })
  });
  const CustomEvent = ({ event }) => (
    <div>
      <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#249393' }}>
        {event.title}
      </div>
      <div style={{ fontSize: '12px', color: '#555' }}>
        Lead ID: {event['Lead ID']}
      </div>
    </div>
  );
  return (
    <Main>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        step={60}
        defaultDate={new Date()}
        startAccessor="start"
        endAccessor="end"
        popup={true}
        components={{
          event: CustomEvent,
        }}
      />
    </Main>
  )
}

const Main = styled.div`
  width: 100%;
  height: calc(100vh - 10vh);
  padding: 1em;
  background-color: #f9f9fc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .rbc-btn-group:first-child button{
    font-size: 13px;
    border: none;
    background-color: #249393;
    cursor: pointer;
    color: white;
    margin-left: 2px ;
    border-radius: 4px;
    padding: 5px 10px;
    transition: all 0.3s;
  }
  .rbc-toolbar-label {
    text-align: center ;
    padding: 0px 20px ;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }

  .rbc-btn-group:last-child {
    font-size: 13px ;
  }

  .rbc-btn-group:last-child button {
    border: none ;
    background-color: #249393 ;
    color: white ;
    margin-left: 2px ;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 10px;
    transition: all 0.3s;
  }

  .rbc-toolbar button.rbc-active {
    background-color: #5DCCCC ;
    box-shadow: none ;
    cursor: pointer;
  }

  .rbc-time-view {
    border-color: #eee ;
  }

  .rbc-time-header {
    display: none ;
  }

  .rbc-time-content {
    border: none ;
  }

.rbc-time-content {
  border: none;
}

.rbc-time-gutter.rbc-time-column {
  font-size: 12px;
}

.rbc-time-gutter.rbc-time-column .rbc-timeslot-group {
  padding: 43.5px 43.5px;
}

.rbc-timeslot-group {
  padding: 50px 50px;
  background-color: #f7fdff;
}

.rbc-day-slot {
  font-size: 14px;
}
  //RBC Agenda
 .rbc-agenda-view {
    background: #f9f9f9; /* Light background for the agenda */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .rbc-agenda-view table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
  }

  .rbc-agenda-view th {
    background-color: #249393;
    color: #ffffff;
    padding: 12px;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 2px solid #0056b3;
  }

  .rbc-agenda-view tbody tr {
    border-bottom: 1px solid #eeeeee;
    transition: background-color 0.2s ease;
  }

  .rbc-agenda-view tbody tr:hover {
    background-color: #f1faff;
  }

  .rbc-agenda-view td {
    padding: 12px;
    font-size: 14px;
    color: #333333;
    vertical-align: top;
  }

  .rbc-agenda-view td:first-child {
    font-weight: bold;
    color: #0056b3;
  }

  .rbc-agenda-view td .event-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  .rbc-agenda-view td .event-time {
    font-size: 14px;
    color: #666666;
  }

  .rbc-agenda-view td .event-description {
    font-size: 13px;
    color: #888888;
  }

  .rbc-agenda-view .no-events {
    text-align: center;
    color: #666666;
    font-size: 16px;
    font-style: italic;
    margin: 20px 0;
  }
  .rbc-agenda-view td {
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .rbc-agenda-view td:hover {
    background-color: #e9f5ff;
    color: #0056b3;
  }

  /* Add more spacing for the agenda table */
  .rbc-agenda-view tbody tr td {
    line-height: 1.5;
  }
  //---------- --------------------
  .rbc-event {
  border-left: 2px solid #249393;
  padding: 5px;
  margin: 2px 0;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.rbc-event:nth-child(1) {
  background-color: #e2f8ff;
}
.rbc-event:nth-child(2) {
  background-color: #fefce8;
}

.rbc-event:nth-child(3) {
  background-color: #f2f1ff;
}

.rbc-event:nth-child(4) {
  background-color: #fdf2fb;
}

.rbc-event:nth-child(5) {
  background-color: #e2f8ff;
}

.rbc-event:nth-child(6) {
  background-color: #fefce8;
}

.rbc-event:nth-child(7) {
  background-color: #f2f1ff;
}

.rbc-event:nth-child(8) {
  background-color: #fdf2fb;
}
.rbc-event-label{
  color: gray ;
  margin-bottom: 5px;
}

  /* .rbc-show-more {
    font-size: 10px ;
    color: #007bff ;
    text-decoration: underline;
    cursor: pointer;
  } */
`;

