import { apiSlice } from "./api/apiSlice";

const employeeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addFavourite: builder.mutation({
      query: (id) => ({
        url: "employee/addfavourite",
        method: "PUT",
        body: id,
      }),
      invalidatesTags: ["Employee", "Leads"],
    }),
    getFavouriteClient: builder.query({
      query: () => ({
        url: "employee/favourite",
      }),
      providesTags: ["Employee"],
    }),
    getLeadsReport: builder.query({
      query: () => ({
        url: "employee/leadsreports",
      }),
      transformResponse: (response) => response.leadReport,
      providesTags: ["Employee"],
    }),
    getDashboardReport: builder.query({
      query: ({ staffId }) => ({
        url: staffId ? `dashboard?employeeId=${staffId}` : "dashboard",
      }),
      transformResponse: (response) => response,
      providesTags: ["Employee"],
    }),
    getQuarterlyLeadTask: builder.query({
      query: () => ({
        url: "employee/quarterlytasks",
      }),
      transformResponse: (response) => response.quarterlytasks,
      providesTags: ["Employee"],
    }),
    getTodayPendingTask: builder.query({
      query: () => ({
        url: "/employee/kpi/today-pending-task",
      }),
      transformResponse: (response) => response,
      providesTags: ["Employee"],
    }),
    getCallReports: builder.query({
      query: () => ({
        url: "employee/callreports",
      }),
      transformResponse: (response) => response.callsReports,
      providesTags: ["Employee"],
    }),
    getClassification: builder.query({
      query: () => ({
        url: "/employee/leads/classification",
      }),
      transformResponse: (response) => response.leadReport,
    }),
    getMeetings: builder.query({
      query: () => ({
        url: "/employee/count/meeting",
      }),
      transformResponse: (response) => response,
    }),
    getStaff: builder.query({
      query: () => ({
        url: "/employee/all-staff",
      }),
      transformResponse: (response) => response.staff,
    }),
    getStaffKpi: builder.query({
      query: ({ staff, start, end, project }) => ({
        url: `/kpi/total-report?user=${JSON.stringify(
          staff
        )}&start=${start}&end=${end}&projectID=${project}`,
      }),
      transformResponse: (response) => response.employeeKPIReports,
    }),
    editStaff: builder.mutation({
      query: ({ staffId, formData }) => ({
        url: `/employee/edit/${staffId}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Employee"],
    }),

    blockStaff: builder.mutation({
      query: ({ staffId }) => ({
        url: `/employee/${staffId}/block`,
        method: "PUT",
      }),
      invalidatesTags: ["Employee"],
    }),
    emailReport: builder.mutation({
      query: ({ data }) => ({
        url: "/kpi/reports-ceo",
        method: "POST",
        body: data,
      }),
    }),
    hierarchyCreate: builder.mutation({
      query: ({ data }) => ({
        url: "/hierarchical/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Employee"],
    }),
    hierarchyUpdate: builder.mutation({
      query: ({ data }) => ({
        url: "/hierarchical/update",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Employee"],
    }),
    getHierarchy: builder.query({
      query: () => ({
        url: "/hierarchical",
      }),
      transformResponse: (response) => response.hierarchical,
      providesTags: ["Employee"],
    }),
    getHierarchysubordinates: builder.query({
      query: (staffId) => ({
        url: `hierarchical/subordinates/${staffId}`,
      }),
      transformResponse: (response) => response.hierarchi,
      providesTags: ["Employee"],
    }),
    getSingleHierarchy: builder.query({
      query: ({ staffId }) => ({
        url: `/hierarchical/${staffId}`,
      }),
      transformResponse: (response) => response.hierarchical,
      providesTags: ["Employee"],
    }),
    deleteSingleStaff: builder.mutation({
      query: ({ parentId, staffId }) => ({
        url: `/hierarchical/employee/${parentId}/subordinate/${staffId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Employee"],
    }),
    assignStaffLeads: builder.mutation({
      query: ({ staffId, data }) => ({
        url: `/client/assign-clients/${staffId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Leads", "Employee"],
    }),
    getTeamClients: builder.query({
      query: ({ teamId }) => ({
        url: `/client/emp/${teamId}`,
      }),
      transformResponse: (response) => response.specEmployeeClients,
      providesTags: ["Employee"],
    }),
    assignSelectedClients: builder.mutation({
      query: ({ data, id }) => ({
        url: `/client/assign-selected-clients/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Leads", "Employee"],
    }),
    assignExelClients: builder.mutation({
      query: ({ data, id }) => ({
        url: `/client/assign-selected-clients/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Leads", "Employee"],
    }),
  }),
});

export const {
  useGetDashboardReportQuery,
  useAddFavouriteMutation,
  useGetFavouriteClientQuery,
  useGetLeadsReportQuery,
  useGetQuarterlyLeadTaskQuery,
  useGetTodayPendingTaskQuery,
  useGetCallReportsQuery,
  useGetClassificationQuery,
  useGetMeetingsQuery,
  useGetStaffQuery,
  useLazyGetStaffKpiQuery,
  useEditStaffMutation,
  useBlockStaffMutation,
  useEmailReportMutation,
  useHierarchyCreateMutation,
  useHierarchyUpdateMutation,
  useGetHierarchyQuery,
  useGetHierarchysubordinatesQuery,
  useGetSingleHierarchyQuery,
  useAssignStaffLeadsMutation,
  useGetTeamClientsQuery,
  useAssignSelectedClientsMutation,
  useDeleteSingleStaffMutation,
  useAssignExelClientsMutation,
} = employeeSlice;
