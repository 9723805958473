import React from "react";
import { TeamHerarchyFirst } from "../../Components/Staff/TeamHerarchy/TeamHerarchyFirst";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import TeamHierarchySecond from "../../Components/Staff/TeamHerarchy/TeamHierarchySecond";
import HierarchyEditTable from "../../Components/Staff/TeamHerarchy/HierarchyEditTable";
import { TeamHerarchyFirst2 } from "../../Components/Staff/TeamHerarchy/TeamHerarchyFirst2";

export const TeamHerarchy = () => {
  let role = localStorage.getItem("role");
  return (
    <Main>
      {role === "ceo" || role === "hr" || role === "v3" || role === "account"? (
        <>
          <TeamHerarchyFirst />
          <TeamHerarchyFirst2 />
          {/* <HierarchyEditTable /> */}
        </>
      ) : null}
      <TeamHierarchySecond />
    </Main>
  );
};

const Main = styled.div`
  background-color: ${colors.inputWhite};
  min-height: calc(100vh - 10vh);
  margin-top: 10vh;
  display: flex;
  padding-top: 2em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
